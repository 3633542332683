<template>
  <credit-pack-form
    :credit-pack="creditPack"
    :validator="validator"
  />
</template>
<script>
  export default {
    props: {
      validator: {
        type: Boolean,
        default: false
      },
      creditPack: {
        type: Object,
        default: () => {},
      }
    },
    components: {
      CreditPackForm: () => import('@form/subscriptions/credit-pack/CreditPackForm')
    }
  }
</script>
<style scoped>

</style>
